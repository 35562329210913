import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/allSite/seo/seo"

import WrapperDivSmall from "../components/StyledElements/WrapperDivSmall"
import Solver404 from "../elements/Solver404"

import { bodyCopyOne, headlineOne, buttonOne } from "../Utilities"

const SolverGraphicStyled = styled.div`
  align-self: center;
  width: calc(100% - 20rem);
  margin: 2rem 10rem;

  @media (min-width: 768px) {
    width: calc(30% - 4rem);
    max-width: 40rem;
    margin: 0 4rem 0 0;
  }
`

const SolverNotFoundContent = styled.div`
  align-self: center;
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(70% - 4rem);
    margin-left: 4rem;
    text-align: left;
  }

  h1 {
    ${headlineOne};
  }

  p {
    ${bodyCopyOne};
  }

  a {
    ${buttonOne};
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <WrapperDivSmall>
      <SolverGraphicStyled>
        <Solver404 />
      </SolverGraphicStyled>
      <SolverNotFoundContent>
        <h1>Oops, sorry.</h1>
        <p>The page you requested was not found.</p>
        <Link to="/">Go home</Link>
      </SolverNotFoundContent>
    </WrapperDivSmall>
  </Layout>
)

export default NotFoundPage
