import React from "react"
import styled from "styled-components"

import { standardWrapper } from "../../Utilities"

const WrapperDivStyled = styled.div`
  ${standardWrapper};
`

const WrapperDivSmall = ({ modifier, children }) => {
  return <WrapperDivStyled className={modifier}>{children}</WrapperDivStyled>
}

export default WrapperDivSmall
