import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const SolverNotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      notFound: file(relativePath: { eq: "solver-404-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Img
      className="solverNotFound"
      fluid={data.notFound.childImageSharp.fluid}
      alt="404 Page not found."
    />
  )
}

export default SolverNotFound
